// export const API_BASE_URL = "https://sds-pg-proxy.codepaperclient.com/api"; // our temporary server url
export const API_BASE_URL = "https://sdspayments.ca:12368/api"; // client's server url

/*
 * bString is used to extract the Decryption Key and the Initialisation Vector
 * to decrypt the endpoint and the logo uri that we get from the server.
 *
 * It uses AES-CBC for encryption.
 *
 * The decryption key is the substring starting at index 169 till 193
 * and the IV is the substring starting at index 237 till 261
 *
 * Head over to the "decrypt()" in Home.js for more info on how it works
 */

export const bString =
  "x1W6sUDg3nRLXYhtAiyOBPOe0xFrOua==M48HtX/f4BGrL7dwUV34wD3L9MckrPnyCyjUQqlnaZ9JRu46f=qxR6pQXPt2g7vDMeQiIgkTGDY4pFN3ZdFkVj3g2y5c9WLsSHd2X1I0H3AYyC8/56iPQgfW=w+igC/Knf4DGNgaw1NJ6TXz+3GA6ImkqC/VZg==f2gKF6DeBh18sNxdWR1A+HD==qQPLL+O7iiRwq94NwUkTdi1s91Ua81XJyR4ulMEjg==CFBcYm/dXSdvmlzHLZtiD4WmsnbfgwUCF5d/v5=j+wur25eXKqvbyS61Y0fHy/VKehG5OPttcpw6yz==CD5XvZ/teH+6j9XQzfTsfrSVXUUgRuOoRNrB46E0fhpDb";

/*
 * Following are the templates for the payloads for different steps,
 * be it save card or payment
 *
 * The template helps as there's some parameters that need to be static
 * for certain requests, e.g for save card, the amount should be $0.00
 *
 * To know more about the steps for different scenarios, check out README
 */

export const saveCardInitialTicketPayload = {
  txn_total: "00.00",
  environment: "qa",
  action: "preload",
  language: "en",
  ask_cvv: "Y",
  cust_id: "",
  dynamic_descriptor: "dyndesc",
  pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
};

export const saveCardReceiptPayload = {
  ticket: "",
  environment: "qa",
  action: "receipt",
  pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
};

export const paymentInitialTicketPayload = {
  txn_total: "",
  environment: "qa",
  action: "preload",
  language: "en",
  ask_cvv: "Y",
  dynamic_descriptor: "dyndesc",
  pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
};

export const paymentReceiptPayload = {
  ticket: "",
  environment: "qa",
  action: "receipt",
  pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
};
