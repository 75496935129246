import '../src/assets/css/All.css';
import '../src/assets/css/Media.css'
import { Route, BrowserRouter as Router, Routes  } from 'react-router-dom';
import Home from '../src/pages/home/Home';
import Success from '../src/components/popup/Success'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/docs" element={<JSDocViewer />} />
        <Route path='/success' element={<Success />} />
      </Routes>
    </Router>
  );
}

const JSDocViewer = () => {
  return (
    <iframe
      src="/out/index.html"
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="JSDoc Documentation"
    />
  );
};

export default App;
